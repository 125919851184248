import { Badge, Container } from 'react-bootstrap';
import styled from 'styled-components';

import { colors } from '../../../layout/styles';

export const SPageContent = styled(Container)`
  min-height: calc(100vh - 800px);
`;

export const SCategoryName = styled.h1`
  font-family: 'Nunito Sans';
  font-weight: 900;
  font-size: 45px;
  color: ${colors.blue};
`;

export const SBadge = styled(Badge)`
  left: unset !important;
  right: 0;
  top: -5px !important;
`;
