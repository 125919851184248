import styled from 'styled-components';
import { Container } from 'react-bootstrap';

import { images } from '../../constants/assets';

import { colors } from './styles';

export const SHeader = styled.div`
  .top {
    background-image: url(${images.frontend.bg});
    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      @media screen and (min-width: 768px) {
        flex-direction: row;
        height: 240px;
      }

      .image {
        display: none;
        max-height: 164px;

        @media screen and (min-width: 768px) {
          display: block;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .title {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: 2rem;
        @media screen and (min-width: 768px) {
          margin-top: 0;
          margin-left: 1.5rem;
        }
        @media screen and (min-width: 992px) {
          margin-left: 2rem;
        }

        h1,
        h2 {
          font-family: 'Nunito Sans';
          color: white;
        }

        h1 {
          font-weight: 900;
          font-size: 30px !important;
          text-align: center;
          @media screen and (min-width: 768px) {
            font-size: 36px !important;
            text-align: left;
          }
          @media screen and (min-width: 992px) {
            font-size: 51px !important;
          }
        }
        h2 {
          font-size: 12px !important;
          font-weight: initial !important;
          letter-spacing: 5px;
          text-align: center;
          @media screen and (min-width: 768px) {
            font-size: 8px !important;
            text-align: left;
          }
          @media screen and (min-width: 992px) {
            font-size: 12px !important;
          }
        }
      }
    }
  }
`;

export const SMenu = styled(Container)`
  border-bottom: 1px solid ${colors.separator};
  .navbar-toggler {
    position: absolute;
    top: 1rem;
    right: 0;
    border: 0;
    background-color: transparent;
    &:active,
    &:focus {
      background-color: transparent !important;
      outline: none !important;
      box-shadow: none !important;
      svg {
        color: ${colors.blue} !important;
      }
    }
  }
  .navbar {
    padding: 0;
    min-height: 4rem;

    .navbar-nav {
      display: flex;

      justify-content: space-between;
      @media screen and (min-width: 992px) {
        margin-left: 2.5rem;
      }

      .nav-item {
        display: flex;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        margin-right: 2.5rem;
        font-size: 0.875rem;
        justify-content: space-between;
        align-items: center;
      }
      .nav-link {
        font-family: 'Nunito Sans';
        font-weight: 800;
        text-transform: uppercase;
        color: ${colors.blue};

        &:hover {
          color: ${colors.orange};
        }
      }
    }
  }
  .icons {
    margin-right: 3rem;
    @media screen and (min-width: 992px) {
      margin-right: 0;
    }
  }
  .menu-icon {
    color: ${colors.blue};

    &:hover {
      color: ${colors.orange};
    }
  }
`;

export const SSubmenu = styled(Container)`
  border-bottom: 1px solid ${colors.separator};
  border-top: 1px solid ${colors.separator};
  .navbar-toggler {
    position: absolute;
    top: 0rem;
    right: 0rem;
    border: 0;
    background-color: transparent;
    &:active,
    &:focus {
      background-color: transparent !important;
      outline: none !important;
      box-shadow: none !important;
      svg {
        color: ${colors.blue} !important;
      }
    }
  }
  .navbar {
    min-height: 2rem;
    padding: 0;
    .navbar-nav {
      .nav-item {
        @media screen and (min-width: 992px) {
          margin: 0 0.5rem;
        }
      }
      .nav-link {
        font-family: 'Nunito Sans';
        font-weight: 800;
        text-transform: uppercase;
        color: ${colors.blue};

        &:hover {
          color: ${colors.orange};
        }

        .menu-icon {
          color: ${colors.blue};

          &:hover {
            color: ${colors.orange};
          }
        }
      }
    }
  }
  .menu-icon {
    color: ${colors.blue};

    &:hover {
      color: ${colors.orange};
    }
  }
`;

export const SMain = styled(Container)``;

export const SFooter = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: ${colors.blue};

  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 160px;
  }
  p {
    margin: 0;
    text-align: center;
    font-size: 16px;
  }
  .copyright {
    color: white;
    font-weight: 700;
  }
  .authors {
    color: #8095c4;
  }
`;
