import React, { useState } from 'react';
import { Button, Collapse, Container, NavItem, NavLink } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useRouteMatch } from 'react-router-dom';

import { SSubmenu } from '../../../layout/styled';
import Icon from '../../../components/IcoMoon';

import { SBadge } from './styled';

const Submenu = () => {
  const match = useRouteMatch();

  const [open, setOpen] = useState(false);

  return (
    <SSubmenu fluid className="px-0">
      <Container className="px-0">
        <div className="nav-scroller py-2">
          <nav className="nav navbar navbar-light navbar-expand-lg d-flex justify-content-between align-items-center">
            <Button className="navbar-toggler" aria-controls="submenuContent" aria-expanded={open} onClick={() => setOpen(!open)}>
              <Icon icon="menu" size={24} className="menu-icon" />
            </Button>
            <Collapse in={open}>
              <div className="navbar-collapse" id="submenuContent">
                <ul className="navbar-nav m-auto">
                  <NavItem>
                    <LinkContainer to={`${match.path}/about`}>
                      <NavLink className="p-2">O klubu</NavLink>
                    </LinkContainer>
                  </NavItem>
                  <NavItem>
                    <LinkContainer to={`${match.path}/messages`}>
                      <NavLink className="p-2">Zprávy</NavLink>
                    </LinkContainer>
                  </NavItem>
                  <NavItem>
                    <LinkContainer to={`${match.path}/schedule`}>
                      <NavLink className="p-2">Rozpis</NavLink>
                    </LinkContainer>
                  </NavItem>
                  <NavItem>
                    <LinkContainer to={`${match.path}/calendar`}>
                      <NavLink className="p-2">Kalendář</NavLink>
                    </LinkContainer>
                  </NavItem>
                  <NavItem>
                    <LinkContainer to={`${match.path}/events`}>
                      <NavLink className="p-2">Události</NavLink>
                    </LinkContainer>
                  </NavItem>
                  <NavItem>
                    <LinkContainer to={`${match.path}/photos`}>
                      <NavLink className="p-2">Fotky</NavLink>
                    </LinkContainer>
                  </NavItem>
                  <NavItem>
                    <LinkContainer to={`${match.path}/videos`}>
                      <NavLink className="p-2">Videa</NavLink>
                    </LinkContainer>
                  </NavItem>
                  <NavItem>
                    <LinkContainer to={`${match.path}/sponsors`}>
                      <NavLink className="p-2">Sponzoři</NavLink>
                    </LinkContainer>
                  </NavItem>
                  <NavItem>
                    <LinkContainer to={`${match.path}/archive`}>
                      <NavLink className="p-2">Archiv</NavLink>
                    </LinkContainer>
                  </NavItem>
                  <NavItem className="position-relative">
                    <NavLink className="p-2" href="https://eshop.fotbal-du.cz" target="_blank">
                      Eshop
                      <SBadge variant="warning">Novinka</SBadge>
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
            </Collapse>
          </nav>
        </div>
      </Container>
    </SSubmenu>
  );
};

export default Submenu;
