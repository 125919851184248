import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import LastThreeArticles from '../../components/Articles/LastThreeArticles';
import MatchesOverview from '../../components/Matches/MatchesOverview';
import LastPlanMessage from '../../components/Messages/LastPlanMessage';
import LastArticles from '../../components/Articles/LastArticles';
import TablesAll from '../../components/Tables/TablesAll';

import { ShadowRow } from './styled';

const HomePage = () => {
  return (
    <Container>
      <Row className="mt-4">
        <LastThreeArticles />
      </Row>
      <ShadowRow className="mt-4 flex-lg-row-reverse">
        <Col xs={12} lg={6} className="p-0">
          <LastPlanMessage />
        </Col>
        <Col xs={12} lg={6} className="p-0">
          <MatchesOverview />
        </Col>
      </ShadowRow>
      <Row className="mt-4 flex-lg-row-reverse">
        <Col xs={12} lg={6} className="p-0">
          <TablesAll />
        </Col>
        <Col xs={12} lg={6} className="p-0 pr-lg-3">
          <LastArticles />
        </Col>
      </Row>
    </Container>
  );
};

export default HomePage;
